import axios from "axios";
import React from "react";
import { selectUser } from "../store/userSlice";
import { store } from "../store/store"; 
import { parseISO, isWithinInterval, startOfMonth, endOfMonth } from "date-fns";
import {
  DashboardOutlined,
  BookOutlined,
  UsergroupAddOutlined,
  SolutionOutlined,
  DollarCircleOutlined,
  SettingOutlined,
  PictureOutlined,
  CarOutlined,
  HomeOutlined,
  TrophyOutlined,
  ReadOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const EMP_BASE_URL = process.env.REACT_APP_BASE_URL_EMP;
const FEE_BASE_URL = process.env.REACT_APP_BASE_URL_FEE;
const STD_BASE_URL = process.env.REACT_APP_BASE_URL_STD;

const BASE_CONFIG = { timeout: 10000, headers: { "Content-Type": "application/json", }, };

export const API = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

// Function to get user dynamically (Assuming `user` is available globally or via function)
//  const user = useSelector(selectUser);

// Add interceptors to include requestedBy in all requests
// const addRequestedByInterceptor = (apiInstance) => {
//   apiInstance.interceptors.request.use((config) => {

 
//   const user = localStorage.getItem("userEmail");

//     if (user) {
//       config.headers["requestedBy"] = user;
//     }

//     return config;
//   });
// };


export const EMP_API = axios.create({
  ...BASE_CONFIG,
  baseURL: EMP_BASE_URL,
  withCredentials: true,
});


export const FEE_API = axios.create({
  ...BASE_CONFIG,
  baseURL: FEE_BASE_URL,
  withCredentials: true,
});



export const STD_API = axios.create({
  ...BASE_CONFIG,
  baseURL: STD_BASE_URL,
  withCredentials: true,
});


// // Utility function to add a "user" header interceptor to any Axios instance
// function addUserHeaderInterceptor(axiosInstance) {
//   axiosInstance.interceptors.request.use((config) => {
//     const userID = getCurrentUserID(); // Replace with your logic to get the userID
//     config.headers['user'] = userID; // Dynamically set the userID in the header
//     return config;
//   }, (error) => {
//     return Promise.reject(error);
//   });
// }

// // Add the interceptor to all instances
// addUserHeaderInterceptor(API);
// addUserHeaderInterceptor(EMP_API);
// addUserHeaderInterceptor(FEE_API);
// addUserHeaderInterceptor(STD_API);

// Example function to get the current userID (replace with your logic)
function getCurrentUserID() {
  // This could be from localStorage, a state management library, etc.
  return localStorage.getItem('userID') || 'defaultUserID';
}
// export const setAuthToken = (token) => {
//   if (token) {
//     API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//     EMP_API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//     FEE_API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//     STD_API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//   } else {
//     delete API.defaults.headers.common["Authorization"];
//     delete EMP_API.defaults.headers.common["Authorization"];
//     delete FEE_API.defaults.headers.common["Authorization"];
//     delete STD_API.defaults.headers.common["Authorization"];
//   }
// };

// API.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token"); // Get token from localStorage (or use cookies)
//   if (token) {
//     config.headers["Authorization"] = `Bearer ${token}`;
//   }
//   return config;
// });

// EMP_API.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers["Authorization"] = `Bearer ${token}`;
//   }
//   return config;
// });

// FEE_API.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers["Authorization"] = `Bearer ${token}`;
//   }
//   return config;
// });

// STD_API.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("token");
//     console.log("Interceptor Token:", token); // Debugging
//     if (token) {
//       config.headers["Authorization"] = `Bearer ${token}`;
//     }
//     console.log("Final Headers:", config.headers); // Check if the header is set
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

export const holidayList = [
  {
    subject: "New Year's Day",
    startDate: "2024-01-01",
    endDate: "2024-01-01",
    type: "Holiday",
  },
  {
    subject: "Republic Day",
    startDate: "2024-01-26",
    endDate: "2024-01-26",
    type: "Holiday",
  },
  {
    subject: "Maha Shivaratri",
    startDate: "2024-03-08",
    endDate: "2024-03-08",
    type: "Holiday",
  },
  {
    subject: "Holi",
    startDate: "2024-03-25",
    endDate: "2024-03-25",
    type: "Holiday",
  },
  {
    subject: "Good Friday",
    startDate: "2024-03-29",
    endDate: "2024-03-29",
    type: "Holiday",
  },
  {
    subject: "Ram Navami",
    startDate: "2024-04-17",
    endDate: "2024-04-17",
    type: "Holiday",
  },
  {
    subject: "Eid al-Fitr",
    startDate: "2024-04-10",
    endDate: "2024-04-10",
    type: "Holiday",
  },
  {
    subject: "Mahavir Jayanti",
    startDate: "2024-04-21",
    endDate: "2024-04-21",
    type: "Holiday",
  },
  {
    subject: "May Day",
    startDate: "2024-05-01",
    endDate: "2024-05-01",
    type: "Holiday",
  },
  {
    subject: "Eid al-Adha",
    startDate: "2024-06-17",
    endDate: "2024-06-17",
    type: "Holiday",
  },
  {
    subject: "Raksha Bandhan",
    startDate: "2024-08-19",
    endDate: "2024-08-19",
    type: "Holiday",
  },
  {
    subject: "Independence Day",
    startDate: "2024-08-15",
    endDate: "2024-08-15",
    type: "Holiday",
  },
  {
    subject: "Janmashtami",
    startDate: "2024-08-26",
    endDate: "2024-08-26",
    type: "Holiday",
  },
  {
    subject: "Ganesh Chaturthi",
    startDate: "2024-09-07",
    endDate: "2024-09-07",
    type: "Holiday",
  },
  {
    subject: "Gandhi Jayanti",
    startDate: "2024-10-02",
    endDate: "2024-10-02",
    type: "Holiday",
  },
  {
    subject: "Dussehra",
    startDate: "2024-10-12",
    endDate: "2024-10-12",
    type: "Holiday",
  },
  {
    subject: "Diwali",
    startDate: "2024-10-31",
    endDate: "2024-10-31",
    type: "Holiday",
  },
  {
    subject: "Guru Nanak Jayanti",
    startDate: "2024-11-15",
    endDate: "2024-11-15",
    type: "Holiday",
  },
  {
    subject: "Christmas",
    startDate: "2024-12-25",
    endDate: "2024-12-25",
    type: "Holiday",
  },
];
export const getCurrentMonthHolidays = () => {
  const currentDate = new Date();
  const startOfCurrentMonth = startOfMonth(currentDate);
  const endOfCurrentMonth = endOfMonth(currentDate);

  return holidayList.filter((holiday) => {
    const startDate = parseISO(holiday.startDate);
    const endDate = parseISO(holiday.endDate);
    return (
      isWithinInterval(startDate, {
        start: startOfCurrentMonth,
        end: endOfCurrentMonth,
      }) ||
      isWithinInterval(endDate, {
        start: startOfCurrentMonth,
        end: endOfCurrentMonth,
      })
    );
  });
};
export const calculateTotalFee = (feeStructure) => {
  if (feeStructure) {
    // Convert string fees to numbers
    const tuitionFee = parseFloat(feeStructure?.tuitionFee);
    const transportFee = parseFloat(feeStructure?.transportFee);
    const otherFees = parseFloat(feeStructure?.otherFees);

    // Sum the fees
    let totalFee = tuitionFee + transportFee + otherFees;

    // Add amounts from the value array
    feeStructure.value.forEach((particular) => {
      totalFee += particular.amount;
    });
    return totalFee;
  }

  return 0;
};

export const educationMediums = [
  { id: 1, name: "English" },
  { id: 2, name: "Hindi" },
  { id: 3, name: "Marathi" },
  { id: 4, name: "Bengali" },
  { id: 5, name: "Tamil" },
  { id: 6, name: "Telugu" },
  { id: 7, name: "Gujarati" },
  { id: 8, name: "Urdu" },
  { id: 9, name: "Punjabi" },
  { id: 10, name: "Malayalam" },
  { id: 11, name: "Kannada" },
  { id: 12, name: "Odia" },
  { id: 13, name: "Assamese" },
  { id: 14, name: "Sanskrit" },
  { id: 15, name: "Konkani" },
];

export const studentMenu = [
  {
    title: "Dashboard",
    key: "dashboard",
    icon: "DashboardOutlined",
    link: "/student/dashboard",
    actions: { add: true, view: true, update: true, delete: true },
  },
  {
    title: "Fee",
    key: "fee",
    icon: "DollarOutlined",
    link: "/student/fee",
    submenu: [],
    actions: { add: true, view: true, update: true, delete: true },
  },
  {
    title: "Result",
    key: "result",
    icon: "RiseOutlined",
    link: "/student/result",
    actions: { add: true, view: true, update: true, delete: true },
  },
  {
    title: "Time Table",
    key: "timetable",
    icon: "CalendarOutlined",
    link: "/student/timetable",
    submenu: [],
    actions: { add: true, view: true, update: true, delete: true },
  },
  {
    title: "Attendance",
    key: "attendance",
    icon: "RiseOutlined",
    link: "/student/attendance",
    submenu: [],
    actions: { add: true, view: true, update: true, delete: true },
  },
  {
    title: "Syllabus",
    key: "syllabus",
    icon: "RiseOutlined",
    link: "/student/syllabus",
    submenu: [],
    actions: { add: true, view: true, update: true, delete: true },
  },
 
  {
    title: "Sports",
    key: "sports",
    icon: "RiseOutlined",
    link: "/student/sports",
    actions: { add: true, view: true, update: true, delete: true },
  },
  {
    title: "Events",
    key: "events",
    icon: "RiseOutlined",
    link: "/student/events",
    submenu: [],
    actions: { add: true, view: true, update: true, delete: true },
  },
  {
    title: "Profile",
    key: "profile",
    icon: "SettingOutlined",
    link: "/student/Profile",
    submenu: [],
    actions: { add: true, view: true, update: true, delete: true },
  },
  {
    title: "Library",
    key: "library",
    icon: "SolutionOutlined",
    link: "/student/library",
    submenu: [],
    actions: { add: true, view: true, update: true, delete: true },
  },
  {
    title: "Worksheet",
    key: "worksheet",
    icon: "SolutionOutlined",
    link: "/student/Worksheet",
    submenu: [],
    actions: { add: true, view: true, update: true, delete: true },
  },
];

export const accountentMenu = [
  {
    title: "Dashboard",
    key: "dashboard",
    icon: "DashboardOutlined",
    link: "/employee/dashboard",
    actions: { add: true, view: true, update: true, delete: true },
  },
  {
    title: "Fee",
    key: "fee",
    icon: "DollarOutlined",
    link: "/employee/fee",
    submenu: [],
    actions: { add: true, view: true, update: true, delete: true },
  },
  {
    title: "Result",
    key: "result",
    icon: "RiseOutlined",
    link: "/employee/result",
    actions: { add: true, view: true, update: true, delete: true },
  },

  {
    title: "Sports",
    key: "sports",
    icon: "RiseOutlined",
    link: "/employee/sports",
    actions: { add: true, view: true, update: true, delete: true },
  },
  {
    title: "Events",
    key: "events",
    icon: "RiseOutlined",
    link: "/employee/events",
    submenu: [],
    actions: { add: true, view: true, update: true, delete: true },
  },
  {
    title: "Profile",
    key: "profile",
    icon: "SettingOutlined",
    link: "/employee/Profile",
    submenu: [],
    actions: { add: true, view: true, update: true, delete: true },
  },
];

export const adminMenu = [
  {
    key: "dashboard",
    icon: "DashboardOutlined",
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    key: "students",
    icon: "UsergroupAddOutlined ",
    title: "Students",
    submenu: [
      { key: "all-students", title: "All Students", link: "/students" },
      { key: "admissions", title: "Admissions", link: "/students/admissions" },
      { key: "attendance", title: "Attendance", link: "/students/attendance" },
      {
        key: "performance",
        title: "Performance",
        link: "/students/performance",
      },
      { key: "library", title: "Library", link: "/students/library" },
    ],
  },
  {
    key: "employees",
    icon: "SolutionOutlined",
    title: "Employees",
    submenu: [
      { key: "all-employees", title: "All Employees", link: "/employees" },
      { key: "add-employee", title: "Add Employee", link: "/employees/add" },
      { key: "attendance", title: "Attendance", link: "/employees/attendance" },
      { key: "salary", title: "Salary Management", link: "/employees/salary" },
      { key: "leaves", title: "Leaves", link: "/employees/leaves" },
    ],
  },
  {
    key: "academics",
    icon: "BookOutlined",
    title: "Academics",
    submenu: [
      { key: "courses", title: "Courses", link: "/courses" },
      { key: "syllabus", title: "Syllabus", link: "/syllabus" },
      { key: "exam-schedule", title: "Exam Schedule", link: "/exam-schedule" },
      { key: "timetable", title: "Class Timetable", link: "/timetable" },
    ],
  },
  {
    key: "finance",
    icon: "DollarCircleOutlined ",
    title: "Finance & Fees",
    submenu: [
      { key: "fee-structure", title: "Fee Structure", link: "/fee-structure" },
      {
        key: "fee-collection",
        title: "Fee Collection",
        link: "/fee-collection",
      },
      { key: "pending-fee", title: "Pending Fees", link: "/pending-fee" },
      { key: "expenses", title: "Expenses", link: "/finance/expenses" },
    ],
  },
  {
    key: "hostel",
    icon: "HomeOutlined ",
    title: "Hostel ",
    submenu: [
      {
        key: "room-allocation",
        title: "Room Allocation",
        link: "/hostel/room-allocation",
      },
      { key: "hostel-fees", title: "Hostel Fees", link: "/hostel/fees" },
    ],
  },
  {
    key: "transport",
    icon: "CarOutlined",
    title: "Transport ",
    submenu: [
      { key: "routes", title: "Bus Routes", link: "/transport/routes" },
      { key: "drivers", title: "Drivers", link: "/transport/drivers" },
    ],
  },
  {
    key: "library",
    icon: "ReadOutlined",
    title: "Library",
    submenu: [
      { key: "books", title: "Books", link: "/library/books" },
      {
        key: "return-books",
        title: "Issued / Return Books",
        link: "/library/return-books",
      },
    ],
  },
  {
    key: "activities",
    icon: "TrophyOutlined ",
    title: "Sports & Events",
    submenu: [
      { key: "sports", title: "Sports", link: "/sports" },
      { key: "events", title: "Cultural Events", link: "/events" },
    ],
  },
  {
    key: "reports",
    icon: "BarChartOutlined ",
    title: "Reports",
    link: "/reports",
  },
  {
    key: "gallery",
    icon: "PictureOutlined ",
    title: "Gallery",
    link: "/gallery",
  },
  {
    key: "settings",
    icon: "SettingOutlined ",
    title: "Settings",
    submenu: [
      { key: "profile", title: "Profile", link: "/settings/profile" },
      { key: "roles", title: "Roles & Permissions", link: "/access" },
    ],
  },
];

export const getMonthName = (monthNumber) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return months[monthNumber - 1] || "Invalid Month";
};

export const getRandomColor = (status = "default") => {
  const statusColors = {
    active: "#28a745", // Green (Default)
    "non-active": "#6c757d", // Gray
    pending: "#ffc107", // Yellow
    approved: "#007bff", // Blue
    rejected: "#dc3545", // Red
    in_progress: "#17a2b8", // Cyan
    default: "#1677ff", // Cyan
  };

  return statusColors[status] || "#1677ff"; // Default to black if status not found
};
