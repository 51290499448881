import React, { useState, useEffect } from "react";
import { Table, Select, DatePicker, Tag, Button, Row, Col, Card } from "antd";
import { UserOutlined, ReloadOutlined } from "@ant-design/icons";
import { divisions, standards } from "../utils/common";
import { STD_API } from "../utils/utils";
import NotAuthorized from "../NotAuthorized";
import dayjs from "dayjs";

const currentDate = dayjs().format("YYYY-MM-DD");
const { Option } = Select;

const ClassAttendanceManagement = ({ user }) => {
  let submenu = user.menus
    .filter((data) => data.key === "students")
    .map((data) => data.submenu);
  let actions = submenu[0]
    .filter((data) => data.key === "attendance")
    .map((data) => data.actions)[0];

  console.log(user);
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [selectedDate, setSelectedDate] = useState(dayjs());

  useEffect(() => {
    fetchAttendance();
  }, [selectedDate]);

  const fetchAttendance = async () => {
    try {
      const response = await STD_API.get(
        "/api/attendance/type/student/date/" + selectedDate.format("YYYY-MM-DD")
      ); // Fetch all students
      setStudents(response.data);
      console.log("attendance",response)
      setFilteredStudents(response.data);
    } catch (error) {
      console.error("Error fetching attendance:", error);
    }
  };

  // Handle filter changes
  const handleFilter = () => {
    let filtered = students;

    if (selectedClass) {
      filtered = filtered.filter(
        (student) => student.standard === selectedClass
      );
    }

    if (selectedDivision) {
      filtered = filtered.filter(
        (student) => student.division === selectedDivision
      );
    }

    if (selectedDate) {
      filtered = filtered.filter(
        (student) => student.date === selectedDate.format("YYYY-MM-DD")
      );
    }

    setFilteredStudents(filtered);
  };

  const presentBoys = filteredStudents.filter(
    (s) => s.status === "Present" && s.gender === "Male"
  ).length;
  const presentGirls = filteredStudents.filter(
    (s) => s.status === "Present" && s.gender === "Female"
  ).length;
  const absentBoys = filteredStudents.filter(
    (s) => s.status === "Absent" && s.gender === "Male"
  ).length;
  const absentGirls = filteredStudents.filter(
    (s) => s.status === "Absent" && s.gender === "Female"
  ).length;
  const totalPresent = filteredStudents.filter(
    (s) => s.status === "Present"
  ).length;
  const totalAbsent = filteredStudents.filter(
    (s) => s.status === "Absent"
  ).length;

  useEffect(() => {
    handleFilter();
  }, [selectedClass, selectedDivision, selectedDate]);

  const handleReset = () => {
    setSelectedClass(null);
    setSelectedDivision(null);
    setSelectedDate(null);
    setFilteredStudents(students); // Restore full student list
  };

  const columns = [
    {
      title: "Student Name",
      dataIndex: "studentName",
      key: "studentName",
      render: (text, record) => <label>{record.student.studentName}</label>,
    },

    {
      title: "Class",
      dataIndex: "standard",
      key: "standard",
      render: (text, record) => <label>{record.student.standard}</label>,
    },
    {
      title: "Division",
      dataIndex: "division",
      key: "division",
      render: (text, record) => <label>{record.student.division}</label>,
    },
 
    {
      title: "Attendance",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Tag color={record.attendance.status === "Present" ? "green" : "red"}>
          {record.attendance.status}
        </Tag>
      ),
    },
  ];

  if (!actions?.view) {
    return <NotAuthorized />;
  }
  return (
    <div>
      <Row
        gutter={[16, 16]}
        align="middle"
        justify="center"
        style={{
          marginBottom: 16,
          padding: "10px",
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        {/* Class Select Dropdown */}
        <Col xs={24} sm={12} md={6} lg={6}>
          <Select
            placeholder="Select Class"
            value={selectedClass}
            onChange={(value) => setSelectedClass(value)}
            allowClear
            style={{ width: "100%" }}
          >
            {standards.map((cls) => (
              <Option key={cls} value={cls}>
                {cls}
              </Option>
            ))}
          </Select>
        </Col>

        {/* Division Select Dropdown */}
        <Col xs={24} sm={12} md={6} lg={6}>
          <Select
            placeholder="Select Division"
            value={selectedDivision}
            onChange={(value) => setSelectedDivision(value)}
            allowClear
            style={{ width: "100%" }}
          >
            {divisions.map((div) => (
              <Option key={div} value={div}>
                {div}
              </Option>
            ))}
          </Select>
        </Col>

        {/* Date Picker */}
        <Col xs={24} sm={12} md={6} lg={6}>
          <DatePicker
            value={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            format="YYYY-MM-DD"
            style={{ width: "100%" }}
          />
        </Col>

        {/* Reset Button */}
        <Col xs={12} sm={12} md={6} lg={3}>
          <Button
            type="primary"
            onClick={handleReset}
            icon={<ReloadOutlined />}
            style={{
              width: "100%",
              fontWeight: "bold",
            }}
          >
            Reset
          </Button>
        </Col>
      </Row>

      <Row gutter={[16, 16]}  justify="center" style={{ marginBottom: 20 }}>
        {/* Present Students Card */}
        <Col xs={24} sm={8} md={8} lg={8}>
          <Card
            bordered={false}
            style={{
              background: "linear-gradient(135deg, #6DD5FA, #1677ff)",
              borderRadius: "6px",
              boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)",
              color: "#fff",
              textAlign: "center",
            }}
          >
            <h3>Present Students</h3>
            <h1>
              {
                students.filter((data) => data.attendance.status === "Present")
                  .length
              }
            </h1>

            <Row justify="space-between">
              <Col
                span={12}
                style={{
                  borderRight: "2px solid rgba(255, 255, 255, 0.5)",
                  paddingRight: "12px",
                }}
              >
                <UserOutlined
                  style={{ fontSize: "20px", color: "#fff", marginBottom: 8 }}
                />
                <p style={{ fontSize: "16px", margin: 0 }}>
                  <b>Boys</b>
                </p>
                <p style={{ fontSize: "20px", fontWeight: "bold", margin: 0 }}>
                  {
                    students.filter(
                      (data) =>
                        data.attendance.status === "Present" &&
                        data.student.gender === "Male"
                    ).length
                  }
                </p>
              </Col>
              <Col span={12}>
                <UserOutlined
                  style={{ fontSize: "20px", color: "#fff", marginBottom: 8 }}
                />
                <p style={{ fontSize: "16px", margin: 0 }}>
                  <b>Girls</b>
                </p>
                <p style={{ fontSize: "20px", fontWeight: "bold", margin: 0 }}>
                  {
                    students.filter(
                      (data) =>
                        data.attendance.status === "Present" &&
                        data.student.gender === "Female"
                    ).length
                  }
                </p>
              </Col>
            </Row>
          </Card>
        </Col>

        {/* Absent Students Card */}
        <Col xs={24} sm={8} md={8} lg={8}>
          <Card
            bordered={false}
            style={{
              background: "linear-gradient(135deg, #FF758C, #FF7EB3)",
              borderRadius: "6px",
              boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)",
              color: "#fff",
              textAlign: "center",
            }}
          >
            <h3>Absent Students</h3>
            <h1>
              {
                students.filter((data) => data.attendance.status === "Absent")
                  .length
              }
            </h1>

            <Row justify="space-between">
              <Col
                span={12}
                style={{
                  borderRight: "2px solid rgba(255, 255, 255, 0.5)",
                  paddingRight: "12px",
                }}
              >
                <UserOutlined
                  style={{ fontSize: "20px", color: "#fff", marginBottom: 8 }}
                />
                <p style={{ fontSize: "16px", margin: 0 }}>
                  <b>Boys</b>
                </p>
                <p style={{ fontSize: "20px", fontWeight: "bold", margin: 0 }}>
                  {absentBoys}
                </p>
              </Col>
              <Col span={12}>
                <UserOutlined
                  style={{ fontSize: "20px", color: "#fff", marginBottom: 8 }}
                />
                <p style={{ fontSize: "16px", margin: 0 }}>
                  <b>Girls</b>
                </p>
                <p style={{ fontSize: "20px", fontWeight: "bold", margin: 0 }}>
                  {
                    students.filter(
                      (data) =>
                        data.attendance.status === "Absent" &&
                        data.student.gender === "Female"
                    ).length
                  }
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Table
        dataSource={filteredStudents}
        columns={columns}
        rowKey="id"
        bordered
      />
    </div>
  );
};

export default ClassAttendanceManagement;
