// import React from "react";
// import { useNavigate } from "react-router-dom";
// import { LockOutlined } from "@ant-design/icons";

// const NotAuthorized = () => {
//   const navigate = useNavigate();

//   return (
//     <div className="not-authorized-container">
//       <LockOutlined className="icon" />
//       <h2>You are not authorized to view this page</h2>
//       <p>Please contact the administrator if you believe this is a mistake.</p>
//       <button onClick={() => navigate(-1)}>Go Back</button>
//     </div>
//   );
// };

// export default NotAuthorized;
import React from "react";
import { useNavigate } from "react-router-dom";
import { LockOutlined } from "@ant-design/icons";
import { Typography, Button, Row, Col } from "antd";

const { Title, Text } = Typography;

const NotAuthorized = () => {
  const navigate = useNavigate();

  return (
    <Row
      justify="center"
      align="middle"
      style={{
        minHeight: "100vh",
        textAlign: "center",
        backgroundColor: "#ffffff",
        padding: "20px",
      }}
    >
      <Col xs={24} sm={20} md={16} lg={10}>
        <div
          style={{
            display: "inline-block",
            background: "#000", // Black background for the icon
            borderRadius: "50%",
            padding: "20px",
            marginBottom: "20px",
          }}
        >
          <LockOutlined style={{ fontSize: "80px", color: "#fff" }} />
        </div>
        <Title level={2} style={{ color: "#333", fontWeight: "bold" }}>
          Access Denied
        </Title>
        <Text style={{ fontSize: "18px", color: "#666" }}>
          You do not have permission to view this page. Please contact the administrator if you believe this is a mistake.
        </Text>
        <div style={{ marginTop: "30px" }}>
          <Button
            type="primary"
            size="large"
            shape="round"
            style={{
              backgroundColor: "#1890ff", // Blue button color
              color: "#fff",
              fontWeight: "bold",
              border: "none",
              padding: "12px 24px",
              fontSize: "16px",
              transition: "0.3s",
              boxShadow: "0px 4px 10px rgba(0,0,0,0.15)",
            }}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#0056b3")}
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#1890ff")}
            onClick={() => navigate(-1)}
          >
            🔙 Go Back
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default NotAuthorized;
