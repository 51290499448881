import { createSlice } from "@reduxjs/toolkit";

// Initial state with the structure of the provided JSON
const initialState = {
  user: {
    id: null,
    email: "",
    menus: [],
    password: "",
    role: "",
    departmentId: null,
  },
};

// Creating the slice
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // Action to save the user object to the state
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

// Exporting the setUser action
export const { setUser } = userSlice.actions;

// Selectors to access parts of the state
export const selectUser = (state) => state.user.user;
export const selectUserEmail = (state) => state.user.user.email;
export const selectUserMenus = (state) => state.user.user.menus;
export const selectUserRole = (state) => state.user.user.role;

// Default export the reducer
export default userSlice.reducer;
